import React, { Component } from "react";
import { Helmet } from "react-helmet";

class PageHelmet extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.props.pageTitle} - App Universe </title>
          <meta
            name="description"
            content="Vi är en appbyrå i Stockholm som designar, utvecklar och förvaltar appar till några av Sveriges största bolag. Vi utför apputveckling både till iOS och Android."
          />
        </Helmet>
      </React.Fragment>
    );
  }
}

export default PageHelmet;
